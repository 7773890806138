<template>
	<a-card class="guest-container">
		<template #title>
			<div class="header">
				<a-button type="primary" @click="handleAdd">新增商户</a-button>
				<a-form layout="inline" style="margin-top: 10px">
					<a-form-item label="商户名称">
						<a-input v-model:value="name" placeholder="请输入商户名称" />
					</a-form-item>
					<a-form-item label="手机号">
						<a-input v-model:value="mobile" placeholder="请输入手机号" />
					</a-form-item>
					<a-form-item>
						<a-button type="primary" @click="getDataList(1)">查询</a-button>
						<a-button style="margin-left: 10px" @click="reset">重置</a-button>
					</a-form-item>
				</a-form>
			</div>
		</template>
		<a-table :loading="loading" :columns="columns" :data-source="tableData" :pagination="false">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'status'">
					<el-popconfirm
						confirm-button-text="确认"
						cancel-button-text="取消"
						title="确认修改应用状态吗"
						@confirm="confirmSwitch(record)"
						@cancel="cancelSwitch(record)"
					>
						<template #reference>
							<el-switch @click="clickSwitch(record)" v-model="record.status" />
						</template>
					</el-popconfirm>
				</template>
				<template v-if="column.key === 'aciton'">
					<span>
						<a @click="handleEdit(record)" style="margin-right: 20px">编辑</a>
						<a @click="changeDiagle('info', record)" style="margin-right: 20px"> 详情 </a>
					</span>
				</template>
			</template>
		</a-table>
		<a-pagination
			style="float: right; margin-top: 10px"
			v-model:current="page_no"
			v-model:pageSize="page_size"
			show-size-changer
			:total="total"
			:show-total="(total) => `总计 ${total} 条`"
			@change="handleChangePage"
		>
			<template v-slot:buildOptionText="props">
				<span>{{ props.value }}条/页</span>
			</template>
		</a-pagination>
	</a-card>
	<a-drawer width="640" placement="right" v-model:visible="dialogVisible" @close="changeDiagle('close')" :closable="false">
		<p class="pStyleTitle">商户详情({{ appInfo.name }})</p>
		<div class="pStyle">
			<IdaasDescription title="商户名称" :text="appInfo.name" />
			<IdaasDescription title="电话号码" :text="appInfo.mobile" />
			<IdaasDescription title="创建人" :text="appInfo.createdUser" />
			<IdaasDescription title="创建时间" :text="appInfo.createdAt" />
			<IdaasDescription title="修改人" :text="appInfo.updatedUser" />
			<IdaasDescription title="修改时间" :text="appInfo.updatedAt" />
			<IdaasDescription title="状态" :text="appInfo.status === 0 ? '禁用' : '启用'" />
		</div>
	</a-drawer>
</template>

<script>
import { onActivated, reactive, ref, toRefs } from "vue"
// import axios from "@/common/axios";
import { useRouter } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"
import IdaasDescription from "@/components/IdaasDescription.vue"
// import { useStore } from "vuex";
export default {
	name: "Application",
	components: {
		IdaasDescription,
	},
	setup() {
		const multipleTable = ref(null)
		const router = useRouter()
		// const store = useStore();
		const state = reactive({
			loading: false,
			appName: "",
			tableData: [
				{
					key: "1",
					name: "商家1",
					appId: "15606530062",
					status: 1,
					outType: 0,
					time: "2021-01-25",
					outTime: "2021-02-15",
				},
			], // 数据列表
			selectList: [], // 选中项
			columns: [
				{
					title: "商家名称",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "手机号",
					dataIndex: "mobile",
					key: "mobile",
				},
				{
					title: "状态",
					dataIndex: "status",
					key: "status",
				},
				{
					title: "创建人",
					dataIndex: "createdUser",
					key: "createdUser",
				},
				{
					title: "修改人",
					dataIndex: "updatedUser",
					key: "updatedUser",
				},
				{
					title: "创建时间",
					dataIndex: "createdAt",
					key: "createdAt",
				},
				{
					title: "修改时间",
					dataIndex: "updatedAt",
					key: "updatedAt",
				},
				{
					title: "操作",
					key: "aciton",
				},
			],
		})
		onActivated(() => {
			getDataList()
		})
		const params = reactive({
			mobile: "",
			name: "",
			total: 0, // 总条数
			page_no: 1, // 当前页
			page_size: 10, // 分页大小
			old_page_size: 10, // 分页大小
		})
		const reset = () => {
			params.name = ""
			params.mobile = ""
			params.total = 0
			params.page_no = 1
			params.page_size = 10
			params.old_page_size = 10
			getDataList()
		}
		const confirmSwitch = (value) => {
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
			console.log(value)

			let data = {
				merchant: {
					id: value.id,
					mobile: value.mobile,
					name: value.name,
					userName: value.userName,
					status: value.status,
				},
			}
			console.log(data)
			axios
				.post(api.saveMerchant, data)
				.then(() => {
					message.success("修改成功")
				})
				.catch(() => {
					state.tableData.forEach((item) => {
						if (item.name == value.name) {
							item.status = !value.status
						}
					})
				})
		}
		const cancelSwitch = () => {}
		const clickSwitch = (value) => {
			console.log(value)
			state.tableData.forEach((item) => {
				if (item.name == value.name) {
					item.status = !value.status
				}
			})
			console.log(state.tableData)
		}
		// 获取列表
		const getDataList = (pageNo) => {
			state.loading = true
			pageNo && (params.page_no = pageNo)
			const data = {
				...params,
			}
			console.log(data)
			axios
				.post(api.getMerchantList, data)
				.then((res) => {
					console.log("getMerchantList", res)
					state.tableData = res.list
					// state.tableData.forEach((item) => {
					//   item.status = item.status === 1 ? true : false;
					// });
					params.total = res.total
					params.page_no = res.page_no
					params.page_size = res.page_size
					params.old_page_size = res.page_size
					state.loading = false
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		// 当前页
		const handleChangePage = (page, pageSize) => {
			params.page_no = params.old_page_size === pageSize ? page : 1
			getDataList()
		}
		// 新增
		const handleAdd = () => {
			router.push({ name: "addMerchant" })
		}
		// 编辑
		const handleEdit = (id) => {
			console.log(id)
			router.push({ name: "addMerchant", query: { id: JSON.stringify(id) } })
		}
		// 详情模板
		const diagleDate = reactive({
			dialogVisible: false,
			appInfo: {},
		})
		const changeDiagle = (type, value) => {
			console.log(value)
			if (type === "info") {
				diagleDate.appInfo = value
				// let data = {
				//   appId: value.appId,
				// };
				// axios.post(api.getMerchantAppDetail, data).then((res) => {
				//   console.log("getMerchantAppDetail", res);
				//
				// });
				diagleDate.dialogVisible = true
			} else {
				diagleDate.dialogVisible = false
			}

			// diagleDate.dialogVisible = !diagleDate.dialogVisible;
		}
		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			multipleTable,
			getDataList,
			handleAdd,
			handleEdit,
			handleChangePage,
			changeDiagle,
			reset,
			confirmSwitch,
			cancelSwitch,
			clickSwitch,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
}
.el-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.pStyle {
	font-size: 12px;
	color: #657180;
}
.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
</style>
